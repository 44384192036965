import { useContext, useEffect, useState } from 'react'
import ContentPage, {
	BottomPage,
	HeaderPage,
} from '../../../../components/Tabs/ContentPage'

import {
	formatCustomWeek,
	getFirstDayOfWeek,
	getCurrentWeekYear,
} from '../../../../helper/ValidateFormat'
import { useSearchParams } from 'react-router-dom'

import WeekTable from './Table'
// import CustomInput from '../../../../components/Datepicker/CustomInput'

import Header from './Header'
import { PageContext } from '../context'
import { useReplaceSearchParams } from '../../../../helper/UrlParams'
import LegendTemperature from '../LegendTemperature'
import TemperatureExport from '../TemperatureExport'
import { startOfWeek } from 'date-fns'

const TemperatureWeek = () => {
	const [searchParams] = useSearchParams()
	const [currentWeek, setCurrentWeek] = useState(null)
	const [deviceid, setDeviceid] = useState(null)
	const [showGraph, setShowGraph] = useState(false)
	const { allDevices } = useContext(PageContext)

	const replaceSearchParams = useReplaceSearchParams()

	useEffect(() => {
		// TODO: Implement a logic to check, if deviceid is in allDevices and currentWeek is possible (helpers should be already there)
		const thisWeek = searchParams.get('week')
		const thisDeviceid = searchParams.get('deviceid')
		const getThisWeek = formatCustomWeek(getFirstDayOfWeek(getCurrentWeekYear()))

		let newParams = {}
		if (!thisWeek && !thisDeviceid) {
			newParams = {
				deviceid: allDevices[0]?.id || null,
				week: getThisWeek,
			}
			replaceSearchParams(newParams)
		} else if (thisWeek && !thisDeviceid) {
			newParams = { deviceid: allDevices[0]?.id || null }
			replaceSearchParams(newParams)
		} else if (!thisWeek && thisDeviceid) {
			newParams = { week: getThisWeek }
			replaceSearchParams(newParams)
		} else {
			setCurrentWeek(getFirstDayOfWeek(thisWeek))
			setDeviceid(thisDeviceid)
		}
	}, [searchParams, allDevices])

	const changeDateHandler = date => {
		setCurrentWeek(startOfWeek(date, { weekStartsOn: 1 }))
		replaceSearchParams({ deviceid, week: formatCustomWeek(date) })
	}

	const toggleChart = () => setShowGraph(!showGraph)

	// TODO: Find a better solution for that
	if (!currentWeek) {
		return <></>
	}

	return (
		<>
			<HeaderPage Export={<TemperatureExport currentWeek={currentWeek} />}>
				<Header
					toggleChart={toggleChart}
					changeDateHandler={changeDateHandler}
					currentDate={currentWeek}
					deviceid={deviceid}
				/>
			</HeaderPage>
			<ContentPage>
				<WeekTable
					currentWeek={currentWeek}
					deviceid={deviceid}
					showGraph={showGraph}
				/>
			</ContentPage>
			<BottomPage>
				<LegendTemperature />
			</BottomPage>
		</>
	)
}

export default TemperatureWeek
