import * as React from 'react'
import {
	CartesianGrid,
	Line,
	LineChart,
	ReferenceLine,
	XAxis,
	YAxis,
} from 'recharts'

import { Card, CardContent } from '../../components/ui/card'
import {
	ChartContainer,
	ChartLegend,
	ChartLegendContent,
	ChartTooltip,
	ChartTooltipContent,
} from '../../components/ui/chart'
import { cn } from '../../lib/utils'
import PropTypes from 'prop-types'

const getMyTimeStamp = (day, hourKey) => {
	const hour = parseInt(hourKey.split('_')[1], 10)
	const timestamp = new Date(
		`${day}T${hour.toString().padStart(2, '0')}:00:00`
	).getTime()
	return timestamp
}

const schema = data =>
	Object.values(
		data.reduce((acc, device) => {
			const { day, values } = device
			Object.entries(values).forEach(([hourKey, valueObj]) => {
				const timestamp = getMyTimeStamp(day, hourKey)
				const temp = valueObj.temp !== null ? Number(valueObj.temp) : null
				if (!acc[timestamp]) acc[timestamp] = { timestamp }
				acc[timestamp][device.device.serial] = temp
			})
			return acc
		}, {})
	)

const config = data =>
	data.reduce((acc, device, index) => {
		const serial = device.device.serial
		if (acc[serial]) return acc

		const color = `hsl(var(--chart-${index + 1}))`
		const label = device.device.name

		acc[serial] = { label, color }
		return acc
	}, {})

export default function NewGraph({
	config: {
		tickFormatter,
		labelFormatter,
		unit,
		showOnlyOneLine,
		referenceValue,
	},
	data,
}) {
	const [activeLine, setActiveLine] = React.useState([])

	const onLegendItemClick = serial => {
		setActiveLine(prev =>
			prev.includes(serial)
				? prev.filter(item => item !== serial)
				: [...prev, serial]
		)
	}
	const mappedData = showOnlyOneLine ? [data[0]] : data

	return (
		<Card className={cn('border-none shadow-none max-w-full')}>
			<CardContent
				className={cn(
					'p-6 pl-1 relative print:pb-0',
					showOnlyOneLine ? 'h-[17rem] pb-2' : 'h-80'
				)}
			>
				<ChartContainer
					config={config(data)}
					handleLegendClick={onLegendItemClick}
					activeLine={activeLine}
					className="h-full w-full"
				>
					<LineChart accessibilityLayer data={schema(data)} margin={{ right: 20 }}>
						<CartesianGrid />
						<XAxis
							dataKey="timestamp"
							tickLine={false}
							axisLine={false}
							tickMargin={4}
							minTickGap={40}
							padding={{ right: 20 }}
							tickFormatter={tickFormatter}
						/>
						<YAxis
							type="number"
							padding={{ top: 1, bottom: 1 }}
							allowDecimals={false}
							tickCount={4}
							minTickGap={2}
							unit={' ' + unit}
						/>
						{referenceValue?.map((val, i) => (
							<ReferenceLine
								key={`ref-line-${val}-${i}`}
								y={val}
								strokeOpacity="0.5"
								stroke={`hsl(var(--chart-${i + 1}))`}
								strokeDasharray="7 7"
							/>
						))}
						<ChartTooltip
							wrapperStyle={{ zIndex: 60 }}
							cursor={false}
							content={
								<ChartTooltipContent
									labelFormatter={labelFormatter}
									unit={' ' + unit}
								/>
							}
						/>
						{mappedData.map(device => (
							<Line
								key={device.device.serial}
								dataKey={device.device.serial}
								type="monotone"
								stroke={config(data)[device.device.serial].color}
								strokeWidth={2}
								dot={false}
								animationDuration={500}
								strokeOpacity={
									activeLine.length === 0 || activeLine.includes(device.device.serial)
										? 1
										: 0
								}
								hide={
									activeLine.length > 0 && !activeLine.includes(device.device.serial)
								}
							/>
						))}
						{!showOnlyOneLine && (
							<ChartLegend
								className="pl-5 flex flex-wrap gap-2"
								content={<ChartLegendContent horizontalAlign="left" />}
							/>
						)}
					</LineChart>
				</ChartContainer>
			</CardContent>
		</Card>
	)
}

NewGraph.propTypes = {
	config: PropTypes.shape({
		tickFormatter: PropTypes.func,
		labelFormatter: PropTypes.func,
		unit: PropTypes.string,
		showOnlyOneLine: PropTypes.bool,
		referenceValue: PropTypes.array,
	}).isRequired,
	data: PropTypes.array.isRequired,
}
