import { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Tooltip } from 'react-tooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faBatteryHalf,
	faBatteryFull,
	faChevronDown,
	faChevronUp,
	faImage,
	faCalendarWeek,
} from '@fortawesome/pro-solid-svg-icons'
import { SystemContext, UserContext } from '../../../../shared/context'
import { fetchPicture } from '../../../../helper/fetch/Attachment'
import LoadingPulse from '../../../../components/Loading/LoadingPulse'
import useRequest from '../../../../hooks/useRequest'

const RowName = ({ device }) => {
	const { setDialogStructure, openDialogHandler } = useContext(SystemContext)
	const { token } = useContext(UserContext)
	const [isExtended, setIsExtended] = useState(false)
	const [battery, setBattery] = useState(null)

	const { Request } = useRequest()

	const showPicture = async e => {
		e.stopPropagation()

		let dialogModal = {
			header: device.name,
			body: <LoadingPulse />,
		}

		openDialogHandler(true)
		setDialogStructure(dialogModal)
		const picture = await fetchPicture(Request, token, device.id)

		if (picture) {
			dialogModal.body = (
				<div className="flex justify-center">
					<img
						className="max-h-dialogImg max-w-dialogImg"
						src={picture.content}
						alt={device.name}
					/>
				</div>
			)
			setDialogStructure({ ...dialogModal })
		} else {
			openDialogHandler(false)
		}
	}

	const batteryColor = bat => {
		if (isNaN(bat)) return { color: 'grey', icon: faBatteryFull, bat }
		if (bat > 40) return { color: 'green', icon: faBatteryFull, bat }
		if (bat > 20) return { color: 'orange', icon: faBatteryHalf, bat }
		return { color: 'red', icon: faBatteryHalf, bat }
	}

	useEffect(() => {
		setBattery(batteryColor(device.battery))
	}, [device.battery])

	return (
		<button
			className="flex flex-col items-center justify-center w-full h-full text-sm cursor-pointer"
			onClick={() => setIsExtended(!isExtended)}
		>
			<div className="flex items-center justify-between w-full">
				<div className="truncate">{device.name}</div>
				<FontAwesomeIcon icon={isExtended ? faChevronUp : faChevronDown} />
			</div>
			{isExtended && (
				<div className="flex items-center justify-center w-full gap-2 mt-1">
					{battery && (
						<div
							data-tooltip-id={'battery-tooltip-' + device.id}
							className="flex items-center justify-center"
						>
							<FontAwesomeIcon
								icon={battery.icon}
								color={battery.color}
								className="w-5 h-5"
							/>
						</div>
					)}
					<div className="text-xs italic grow">{device.serial}</div>
					<div className="flex items-center justify-center">
						<FontAwesomeIcon
							data-tooltip-id={'picture-tooltip-' + device.id}
							onClick={showPicture}
							icon={faImage}
							className="w-4 h-4"
						/>
					</div>
					{/* <i className="fa-kit fa-solid-memo-magnifying-glass fa-lg" /> */}

					<NavLink to={'../week?deviceid=' + device.id}>
						<div className="flex items-center justify-center">
							<FontAwesomeIcon
								data-tooltip-id={'showweek-tooltip-' + device.id}
								icon={faCalendarWeek}
								className="w-4 h-4"
							/>
						</div>
					</NavLink>

					<Tooltip
						id={'battery-tooltip-' + device.id}
						content={isNaN(battery.bat) ? 'nicht verfügbar' : battery.bat + ' %'}
					/>
					<Tooltip id={'picture-tooltip-' + device.id} content="Installationsfoto" />
					<Tooltip id={'showweek-tooltip-' + device.id} content="Wochenansicht" />
				</div>
			)}
		</button>
	)
}

RowName.propTypes = {
	device: PropTypes.object,
}

export default RowName
