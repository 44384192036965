import { useContext, useState } from 'react'

import { PageContext } from '../context'

import { format } from 'date-fns'

import BackButton from './BackButton'

import de from 'date-fns/locale/de-AT'
import SimulateTable from '../../../../shared/SimulateTable/SimulateTable'
import EmptyTable from '../../../../shared/EmptyTable'
import PropTypes from 'prop-types'
import DataTableTemperature from '../DataTableTemperature'
import useAbortFetch from '../../../../hooks/useAbortFetch'
import hourCell from '../CustomCell'
import { SystemContext } from '../../../../shared/context'

const columns = device => {
	const allColumns = []

	// First Column
	allColumns.push({
		name: <BackButton device={device} />,
		cell: row => (
			<div>
				{format(row.day, 'E', { locale: de }) +
					', ' +
					format(row.day, 'dd.MM.yyyy')}
			</div>
		),
		grow: 5.5,
		minWidth: '230px',
		maxWidth: '350px',
	})

	// Hourly Columns
	for (let hour = 0; hour <= 23; hour++) {
		allColumns.push(hourCell(hour))
	}

	return allColumns
}

const getGraphConfig = (device, systemJson) => {
	return {
		labelFormatter: tick =>
			new Date(tick).toLocaleString([], {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
			}),

		tickFormatter: tick => format(new Date(tick), 'dd.MM., HH:mm'),
		unit: '°C',
		showOnlyOneLine: true,
		referenceValue: [
			systemJson?.temperatureThresholds.find(
				({ place }) => place === device?.attributes?.installation_place
			)?.target ?? null,
		],
	}
}

// TODO: Some weird behavior, that temperatureData has still in the "day" data.
const WeekTable = ({ currentWeek, deviceid, showGraph }) => {
	const { getTemperatureData, temperatureData, allDevices } =
		useContext(PageContext)
	const { systemJson } = useContext(SystemContext)

	const [device, setDevice] = useState(null)

	const initData = async abortController => {
		const timeRange = { startDate: currentWeek, days: 7 }
		const currentDevice = allDevices.find(d => d?.id === deviceid)
		if (currentDevice) {
			await getTemperatureData(abortController, timeRange, [currentDevice])
		} else {
			await getTemperatureData(abortController, timeRange, [])
		}
		setDevice(currentDevice)
	}

	useAbortFetch(initData, [currentWeek, deviceid])

	return (
		<>
			{temperatureData?.length > 0 && (
				<DataTableTemperature
					columns={columns(device)}
					data={temperatureData}
					showGraph={showGraph}
					config={getGraphConfig(device, systemJson)}
				/>
			)}
			{temperatureData === null && (
				<SimulateTable
					columns={columns(device)}
					dummyEntry={{ values: {}, device: {} }}
				/>
			)}
			{temperatureData?.length === 0 && (
				<EmptyTable>
					Es konnten leider keine Geräte in dieser Filiale gefunden werden!
				</EmptyTable>
			)}
		</>
	)
}

WeekTable.propTypes = {
	currentWeek: PropTypes.instanceOf(Date).isRequired,
	deviceid: PropTypes.string.isRequired,
	showGraph: PropTypes.bool,
}

export default WeekTable
