import PropTypes from 'prop-types'
import DataTable from 'react-data-table-component'
import customStylesRows from '../../../shared/SimulateTable/customStyles'
import NewGraph from '../../../components/TemperatureGraph/NewGraph'
import { useIsPrintMode } from '../../../hooks/useIsPrintMode'

const conditionalRowStyles = [
	{
		when: row => row.device.inactive,
		classNames: ['!text-gray-400'],
	},
]

const DataTableTemperature = ({ columns, data, showGraph, config }) => {
	const isPrintMode = useIsPrintMode()

	return (
		<>
			{showGraph && <NewGraph config={config} data={data} />}

			<DataTable
				fixedHeader={!isPrintMode}
				fixedHeaderScrollHeight={
					!isPrintMode
						? showGraph
							? config?.showOnlyOneLine
								? 'calc(100vh - 35rem)'
								: 'calc(100vh - 37rem)'
							: 'calc(100vh - 17rem)'
						: undefined
				}
				highlightOnHover
				columns={columns}
				data={data}
				customStyles={customStylesRows}
				className={isPrintMode ? '!overflow-hidden' : ''}
				conditionalRowStyles={conditionalRowStyles}
			/>
		</>
	)
}

DataTableTemperature.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	showGraph: PropTypes.bool,
	config: PropTypes.object,
}

export default DataTableTemperature
