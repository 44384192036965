import { useContext, useState } from 'react'
import Dropdown from '../../../../shared/Custom/Dropdown/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faChartLine,
	faChevronLeft,
	faChevronRight,
	faList,
} from '@fortawesome/pro-solid-svg-icons'
import { PageContext } from '../context'

import { formatCustomWeek } from '../../../../helper/ValidateFormat'
import { addDays, endOfWeek, format } from 'date-fns'
import { useReplaceSearchParams } from '../../../../helper/UrlParams'

import de from 'date-fns/locale/de-AT'
import DisplayDate from '../DisplayDate'
import PropTypes from 'prop-types'
import CustomCalendar from '../../../../shared/Custom/CustomCalendar/CustomCalendar'
import { HeaderButtons } from '../../../../components/Tabs/HeaderButtons'
import { finalInactiveSensors } from '../../../../shared/format'
import { SystemContext } from '../../../../shared/context'

const handleWeekSwitch = (date, addDays) => {
	const resultDate = new Date(date)
	resultDate.setDate(resultDate.getDate() + addDays)
	return resultDate
}

const germanDate = date => {
	const firstDate = format(date, 'dd. MMMM yyyy', { locale: de })
	const secondDate = format(addDays(date, 6), 'dd. MMMM yyyy', { locale: de })

	return `KW ${formatCustomWeek(date).slice(0, 2)} | ${firstDate} - ${secondDate}`
}

const InsideDropDownDevices = () => (
	<HeaderButtons text="Geräte">
		<FontAwesomeIcon icon={faList} className="w-5 h-5" />
	</HeaderButtons>
)

const Header = ({ currentDate, deviceid, changeDateHandler, toggleChart }) => {
	const { systemJson } = useContext(SystemContext)
	const { allDevices } = useContext(PageContext)
	const [showDropdown, setShowDropdown] = useState(false)

	const replaceSearchParams = useReplaceSearchParams()

	const changeDeviceId = value => {
		const newParams = { deviceid: value }
		replaceSearchParams(newParams)
		setShowDropdown(false)
	}

	const prevWeek = () => {
		const prevWeekDate = handleWeekSwitch(currentDate, -7)
		const newParams = { week: formatCustomWeek(prevWeekDate) }
		replaceSearchParams(newParams)
	}
	const nextWeek = () => {
		const nextWeekDate = handleWeekSwitch(currentDate, 7)
		const newParams = { week: formatCustomWeek(nextWeekDate) }
		replaceSearchParams(newParams)
	}

	return (
		<>
			<div className="flex h-full gap-0.5">
				<Dropdown
					dropdownId="tempWeekDevices"
					position="left"
					renderTrigger={InsideDropDownDevices}
					showDropdown={showDropdown}
					setShowDropdown={setShowDropdown}
					className="min-w-56"
				>
					{allDevices.map(device => (
						<button
							key={'dropdown_device_list_' + device.id}
							onClick={() => {
								changeDeviceId(device.id)
							}}
							className={
								'cursor-pointer hover:bg-gray-200' +
								(device.id === deviceid ? ' bg-gray-100' : '') +
								(finalInactiveSensors(
									systemJson.temperatureThresholds,
									device.attributes
								)
									? ' text-gray-400'
									: '')
							}
						>
							{device.filter}
						</button>
					))}
				</Dropdown>
				<CustomCalendar
					id="week"
					changeDateHandler={changeDateHandler}
					currentDate={{
						from: currentDate,
						to: endOfWeek(currentDate, { weekStartsOn: 1 }),
					}}
					today="Aktuelle Woche"
				/>
				<HeaderButtons onClick={toggleChart} text="Verlauf">
					<FontAwesomeIcon icon={faChartLine} className="w-5 h-5" />
				</HeaderButtons>
				<HeaderButtons onClick={prevWeek}>
					<FontAwesomeIcon icon={faChevronLeft} className="w-5 h-5" />
				</HeaderButtons>
				<HeaderButtons onClick={nextWeek}>
					<FontAwesomeIcon icon={faChevronRight} className="w-5 h-5" />
				</HeaderButtons>
			</div>
			<DisplayDate>{germanDate(currentDate)}</DisplayDate>
		</>
	)
}

Header.propTypes = {
	currentDate: PropTypes.instanceOf(Date).isRequired,
	deviceid: PropTypes.string.isRequired,
	changeDateHandler: PropTypes.func.isRequired,
	toggleChart: PropTypes.func.isRequired,
}

export default Header
