/* eslint-disable tailwindcss/no-arbitrary-value */
/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable react/prop-types */
import * as React from 'react'
import * as RechartsPrimitive from 'recharts'

import { cn } from '../../lib/utils'

// Format: { THEME_NAME: CSS_SELECTOR }
const THEMES = {
	light: '',
	dark: '.dark',
}

const ChartContext = React.createContext({
	config: {},
	activeLine: [],
	changeActiveLine: () => {},
})

function useChart() {
	const context = React.useContext(ChartContext)

	if (!context) {
		throw new Error('useChart must be used within a <ChartContainer />')
	}

	return context
}

const ChartContainer = React.forwardRef(
	(
		{ id, className, children, config, handleLegendClick, activeLine, ...props },
		ref
	) => {
		const uniqueId = React.useId()
		const chartId = `chart-${id || uniqueId.replace(/:/g, '')}`

		return (
			<ChartContext.Provider
				value={{ config, activeLine, changeActiveLine: handleLegendClick }}
			>
				<div
					data-chart={chartId}
					ref={ref}
					className={cn(
						// eslint-disable-next-line quotes
						`flex aspect-video justify-center text-xs [&_.recharts-cartesian-axis-tick_text]:fill-muted-foreground [&_.recharts-cartesian-grid_line[stroke='#ccc']]:stroke-border/50 [&_.recharts-curve.recharts-tooltip-cursor]:stroke-border [&_.recharts-dot[stroke='#fff']]:stroke-transparent [&_.recharts-layer]:outline-none [&_.recharts-polar-grid_[stroke='#ccc']]:stroke-border [&_.recharts-radial-bar-background-sector]:fill-muted [&_.recharts-rectangle.recharts-tooltip-cursor]:fill-muted [&_.recharts-reference-line_[stroke='#ccc']]:stroke-border [&_.recharts-sector[stroke='#fff']]:stroke-transparent [&_.recharts-sector]:outline-none [&_.recharts-surface]:outline-none`,
						className
					)}
					{...props}
				>
					<ChartStyle id={chartId} config={config} />
					<RechartsPrimitive.ResponsiveContainer>
						{React.Children.map(children, child =>
							React.cloneElement(child, {
								activeLine,
								onLegendItemClick: handleLegendClick,
							})
						)}
					</RechartsPrimitive.ResponsiveContainer>
				</div>
			</ChartContext.Provider>
		)
	}
)
ChartContainer.displayName = 'Chart'

const ChartStyle = ({ id, config }) => {
	const colorConfig = Object.entries(config).filter(
		([, config]) => config.theme || config.color
	)

	if (!colorConfig.length) {
		return null
	}

	return (
		<style
			dangerouslySetInnerHTML={{
				__html: Object.entries(THEMES)
					.map(
						([theme, prefix]) => `
${prefix} [data-chart=${id}] {
${colorConfig
	.map(([key, itemConfig]) => {
		const color = itemConfig.theme?.[theme] || itemConfig.color
		return color ? `  --color-${key}: ${color};` : null
	})
	.join('\n')}
}
`
					)
					.join('\n'),
			}}
		/>
	)
}

const ChartTooltip = RechartsPrimitive.Tooltip

const ChartTooltipContent = React.forwardRef(
	(
		{
			active,
			payload,
			className,
			indicator = 'dot',
			hideLabel = false,
			hideIndicator = false,
			label,
			labelFormatter,
			labelClassName,
			formatter,
			color,
			nameKey,
			labelKey,
			unit,
		},
		ref
	) => {
		const { config, activeLine } = useChart()

		const tooltipLabel = React.useMemo(() => {
			if (hideLabel || !payload?.length) {
				return null
			}

			const [item] = payload
			const key = `${labelKey || item.dataKey || item.name || 'value'}`
			const itemConfig = getPayloadConfigFromPayload(config, item, key)
			const value =
				!labelKey && (typeof label === 'string' || typeof label === 'number')
					? config[label]?.label || label
					: itemConfig?.label

			if (labelFormatter) {
				return (
					<div className={cn('font-medium', labelClassName)}>
						{labelFormatter(value, payload)}
					</div>
				)
			}

			if (!value) {
				return null
			}

			return <div className={cn('font-medium', labelClassName)}>{value}</div>
		}, [
			label,
			labelFormatter,
			payload,
			hideLabel,
			labelClassName,
			config,
			labelKey,
		])

		if (!active || !payload?.length) {
			return null
		}

		const nestLabel = payload.length === 1 && indicator !== 'dot'

		return (
			<div
				ref={ref}
				className={cn(
					'z-50 grid min-w-[8rem] items-start gap-1.5 rounded-lg border border-border/50 bg-background px-2.5 py-1.5 text-xs shadow-xl',
					className
				)}
			>
				{!nestLabel ? tooltipLabel : null}
				<div className="grid gap-1.5">
					{payload.map((item, index) => {
						const key = `${nameKey || item.name || item.dataKey || 'value'}`
						const itemConfig = getPayloadConfigFromPayload(config, item, key)
						const indicatorColor = color || item.payload.fill || item.color
						const isActive =
							activeLine?.length === 0 || activeLine?.includes(item.dataKey) // Determine if active

						return (
							<div
								key={item.dataKey}
								className={cn(
									'flex w-full flex-wrap items-stretch gap-2 [&>svg]:h-2.5 [&>svg]:w-2.5 [&>svg]:text-muted-foreground',
									indicator === 'dot' && 'items-center'
								)}
							>
								{formatter && item?.value !== undefined && item.name ? (
									formatter(item.value, item.name, item, index, item.payload)
								) : (
									<>
										{itemConfig?.icon ? (
											<itemConfig.icon />
										) : (
											!hideIndicator && (
												<div
													className={cn(
														'shrink-0 rounded-[2px] border-[--color-border] bg-[--color-bg]',
														{
															'h-2.5 w-2.5': indicator === 'dot',
															'w-1': indicator === 'line',
															'w-0 border-[1.5px] border-dashed bg-transparent':
																indicator === 'dashed',
															'my-0.5': nestLabel && indicator === 'dashed',
														}
													)}
													style={{
														'--color-bg': indicatorColor,
														'--color-border': indicatorColor,
													}}
												/>
											)
										)}
										<div
											className={cn(
												'flex flex-1 justify-between leading-nones gap-2',
												nestLabel ? 'items-end' : 'items-center',
												!activeLine
													? 'opacity-100'
													: isActive
														? 'opacity-100'
														: 'opacity-50' // Apply bold if active
											)}
										>
											<div className="grid gap-1.5">
												{nestLabel ? tooltipLabel : null}
												<span className="text-muted-foreground">
													{itemConfig?.label || item.name}
												</span>
											</div>
											{item.value && (
												<span className="font-mono font-medium tabular-nums text-foreground">
													{item.value.toLocaleString()}
													{unit}
												</span>
											)}
										</div>
									</>
								)}
							</div>
						)
					})}
				</div>
			</div>
		)
	}
)
ChartTooltipContent.displayName = 'ChartTooltip'

const ChartLegend = RechartsPrimitive.Legend

const ChartLegendContent = React.forwardRef(
	(
		{
			className,
			hideIcon = false,
			payload,
			verticalAlign = 'bottom',
			nameKey,
			horizontalAlign = 'center',
		},
		ref
	) => {
		const { config, activeLine, changeActiveLine } = useChart()

		if (!payload?.length) {
			return null
		}

		const isActive = dataKey =>
			activeLine?.length === 0 || activeLine?.includes(dataKey)

		return (
			<div
				ref={ref}
				className={cn(
					'flex items-center gap-4',
					verticalAlign === 'top' ? 'pb-3' : 'pt-3',
					horizontalAlign === 'left'
						? 'justify-start'
						: horizontalAlign === 'right'
							? 'justify-end'
							: 'justify-center',
					className
				)}
			>
				{payload.map(item => {
					const key = `${nameKey || item.dataKey || 'value'}`
					const itemConfig = getPayloadConfigFromPayload(config, item, key)
					const isSelected = isActive(item.dataKey)

					return (
						<button
							key={item.value}
							onClick={() => changeActiveLine?.(item.dataKey)}
							style={{
								opacity: isSelected ? 1 : 0.5,
							}}
							className={cn(
								'whitespace-nowrap items-center flex gap-1.5 [&>svg]:h-3 [&>svg]:w-3 [&>svg]:text-muted-foreground'
							)}
						>
							{itemConfig?.icon && !hideIcon ? (
								<itemConfig.icon />
							) : (
								<div
									className="h-2 w-2 shrink-0 rounded-[2px]"
									style={{
										backgroundColor: item.color,
									}}
								/>
							)}
							<span>{itemConfig?.label}</span>
						</button>
					)
				})}
			</div>
		)
	}
)
ChartLegendContent.displayName = 'ChartLegend'

// Helper to extract item config from a payload.
function getPayloadConfigFromPayload(config, payload, key) {
	if (typeof payload !== 'object' || payload === null) {
		return undefined
	}

	const payloadPayload =
		'payload' in payload &&
		typeof payload.payload === 'object' &&
		payload.payload !== null
			? payload.payload
			: undefined

	let configLabelKey = key

	if (
		key in payload &&
		(typeof payload[key] === 'string' || typeof payload[key] === 'number')
	) {
		configLabelKey = payload[key]
	} else if (
		payloadPayload &&
		key in payloadPayload &&
		(typeof payloadPayload[key] === 'string' ||
			typeof payloadPayload[key] === 'number')
	) {
		configLabelKey = payloadPayload[key]
	}

	return configLabelKey in config ? config[configLabelKey] : config[key]
}

export {
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
	ChartLegend,
	ChartLegendContent,
	ChartStyle,
	ChartContext,
}
