import { isValid, parse, isLeapYear, format } from 'date-fns'

const getWeekNumber = d => {
	d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
	d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
	const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
	const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7)
	return weekNo
}

export const getCurrentWeekYear = () => {
	const now = new Date()
	const week = getWeekNumber(now)
	const year = now.getFullYear()
	return `${week < 10 ? '0' + week : week}${year}`
}

export const getFirstDayOfWeek = input => {
	const regex = /^\d{2}\d{4}$/
	if (!regex.test(input)) {
		input = getCurrentWeekYear()
		// TODO: Add Code for "this is not a valid dateinput"
		// CodeHandler()
	}

	const week = parseInt(input.substring(0, 2), 10)
	const year = parseInt(input.substring(2, 6), 10)
	if (week < 1 || week > 53) return null

	// January 4th is always in week 1 per ISO 8601
	const january4 = new Date(year, 0, 4)
	let dayOfWeek = january4.getDay()
	if (dayOfWeek === 0) dayOfWeek = 7
	if (week === 53) {
		const firstDayOfYear = new Date(year, 0, 1)
		let startDay = firstDayOfYear.getDay()
		if (startDay === 0) startDay = 7
		const has53Weeks = startDay === 4 || (startDay === 3 && isLeapYear(year))
		if (!has53Weeks) return null
	}

	let firstWeekMonday = new Date(year, 0, 4 - (dayOfWeek - 1))
	const newDate = firstWeekMonday.getDate() + (week - 1) * 7
	firstWeekMonday.setDate(newDate)

	if (
		firstWeekMonday.getFullYear() !== year ||
		getWeekNumber(firstWeekMonday) !== week
	) {
		firstWeekMonday = null
	}

	return firstWeekMonday
}

export const formatCustomWeek = d => {
	const weekNo = getWeekNumber(d)
	const year = d.getFullYear()
	return `${weekNo < 10 ? '0' + weekNo : weekNo}${year}`
}

export const formatCustomMonth = d => {
	return format(d, 'MMyyyy')
}

export const validateDateFormat = (input, format) => {
	if (!input) return null

	const date = parse(input, format, new Date())

	if (!isValid(date)) return null

	return date
}

export const getMonthFormat = MMyyyy => {
	return parse(MMyyyy, 'MMyyyy', new Date())
}
