import { useEffect, useState } from 'react'
import ContentPage, {
	BottomPage,
	HeaderPage,
} from '../../../../components/Tabs/ContentPage'

import DayTable from './Table'
import { validateDateFormat } from '../../../../helper/ValidateFormat'
import { useSearchParams } from 'react-router-dom'
import { format } from 'date-fns'

import Header from './Header'
import TemperatureExport from '../TemperatureExport'
import { useReplaceSearchParams } from '../../../../helper/UrlParams'
import LegendTemperature from '../LegendTemperature'

const TemperatureDay = () => {
	const [searchParams] = useSearchParams()
	const [currentDay, setCurrentDay] = useState(null)
	const [showGraph, setShowGraph] = useState(false)
	const replaceSearchParams = useReplaceSearchParams()

	useEffect(() => {
		const checkDay = validateDateFormat(searchParams.get('date'), 'yyyyMMdd')

		if (checkDay) {
			setCurrentDay(checkDay)
		} else {
			replaceSearchParams({ date: format(new Date(), 'yyyyMMdd') })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams])

	const changeDateHandler = date => {
		setCurrentDay(date)
		replaceSearchParams({ date: format(date, 'yyyyMMdd') })
	}

	const toggleChart = () => setShowGraph(!showGraph)

	// TODO: Find a better solution for that
	if (!currentDay) {
		return <></>
	}

	return (
		<div>
			<HeaderPage Export={<TemperatureExport currentDay={currentDay} />}>
				<Header
					toggleChart={toggleChart}
					changeDateHandler={changeDateHandler}
					currentDate={currentDay}
				/>
			</HeaderPage>
			<ContentPage>
				<DayTable currentDay={currentDay} showGraph={showGraph} />
			</ContentPage>
			<BottomPage>
				<LegendTemperature />
			</BottomPage>
		</div>
	)
}

export default TemperatureDay
